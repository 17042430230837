import React, { Component } from 'react';
//import logo from './logo.svg';
//import './App.css';
import "./assets/web/assets/mobirise-icons/mobirise-icons.css";
import "./assets/tether/tether.min.css";
import 'bootstrap/dist/css/bootstrap.css';
//import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
//import Carousel from "./Carousel.js";
import Forma from "./Forma.js";

import "./assets/dropdown/css/style.css";
import "./assets/socicon/css/styles.css";
import "./assets/theme/css/style.css";
import "./assets/mobirise/css/mbr-additional.css";
import logo from "./assets/images/4-120x100.png";
import prva from "./assets/images/trenja-burlat-676x676.jpg";

import city from "./assets/images/cityscape.png";
import kutija from "./assets/images/kutija.jpg";
import slavko from "./assets/images/face6.jpg";
import sava from "./assets/images/Sava.jpg";
import Zora from "./assets/images/Zora.jpg";
import deka from "./assets/images/deka.jpg";

class App extends Component {
  render() {
    return (
      <div>
        <section class="menu cid-qR4z4bOPiM" once="menu" id="menu1-0">
            <nav class="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div class="menu-logo">
                    <div class="navbar-brand">
                        <span class="navbar-logo">
                            <a href="index.html">
                                <img src={logo} alt="Prirodno" title="TEREŠNJE" />
                            </a>
                        </span>
                        <span class="navbar-caption-wrap"><a class="navbar-caption text-white display-4" href="index.html#"></a></span>
                    </div>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav nav-dropdown" data-app-modern-menu="true">
                        <li class="nav-item">
                            <a class="nav-link link text-white display-4" href="tel://+381640137706"><span class="socicon socicon-whatsapp mbr-iconfont mbr-iconfont-btn">+381640137706</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link link text-white display-4" href="index.html#features16-a"><span class="mbri-briefcase mbr-iconfont mbr-iconfont-btn"></span>
                                
                                O nama</a>
                        </li></ul>
                    <div class="navbar-buttons mbr-section-btn"><a class="btn btn-sm btn-primary display-4" href="index.html#form1-y"><span class="mbri-delivery mbr-iconfont mbr-iconfont-btn"></span></a></div>
                </div>
            </nav>
        </section>

        <section class="engine"><a href="index.html#">domaće voće</a></section>

        <section class="header10 cid-qR4z8xjKUt mbr-fullscreen mbr-parallax-background" id="header10-1">
            <div class="container">
                <div class="media-container-column mbr-white col-lg-8 col-md-10 ml-auto">
                    <h1 class="mbr-section-title align-right mbr-bold pb-3 mbr-fonts-style display-1">SVEŽE TREŠNJE VRHUNSKOG KVALITETA&nbsp;</h1>
                    <h3 class="mbr-section-subtitle align-right mbr-light pb-3 mbr-fonts-style display-2">dostava na teritoriji Beograda</h3>
                    <p class="mbr-text align-right pb-3 mbr-fonts-style display-5">Poručite danas za dostavu sutra. <br/>Sezona trešanja ne traje dugo, požurite! &nbsp;</p>
                    <div class="mbr-section-btn align-right"><a class="btn btn-md btn-primary display-4" href="index.html#features16-a">SAZNAJTE VIŠE</a>
                      <a class="btn btn-md btn-white-outline display-4" href="https://www.youtube.com/embed/3NO_fNf3W58">OVDE RASTU</a></div>
                </div>
            </div>

            <div class="mbr-arrow hidden-sm-down" aria-hidden="true">
                <a href="#next">
                    <i class="mbri-down mbr-iconfont"></i>
                </a>
            </div>
        </section>

        <section class="features3 cid-qR4zigkUo7" id="features3-2">
            <div class="container">
                <div class="media-container-row">
                    <div class="card p-3 col-12 col-md-6 col-lg-4">
                        <div class="card-wrapper">
                            <div class="card-img">
                                <img src={prva} alt="Mobirise" title="burlat"/>
                            </div>
                            <div class="card-box">
                                <h4 class="card-title mbr-fonts-style display-7">
                                    Burlat i ostale sorte</h4>
                                <p class="mbr-text mbr-fonts-style display-7">
                                    Požurite, količine su ograničene.. 
                                </p>
                            </div>
                            <div class="mbr-section-btn text-center"><a href="" class="btn btn-primary display-4">poruči</a></div>
                        </div>
                    </div>

                    <div class="card p-3 col-12 col-md-6 col-lg-4">
                        <div class="card-wrapper">
                            <div class="card-img">
                                <img src={city} alt="Mobirise"/>
                            </div>
                            <div class="card-box">
                                <h4 class="card-title mbr-fonts-style display-7">
                                    Beograd
                                </h4>
                                <p class="mbr-text mbr-fonts-style display-7">
                                    Isporuka na užoj teritoriji grada Beograda
                                </p>
                            </div>
                            <div class="mbr-section-btn text-center"><a href="index.html#form1-y" class="btn btn-primary display-4">poruči</a></div>
                        </div>
                    </div>

                    <div class="card p-3 col-12 col-md-6 col-lg-4">
                        <div class="card-wrapper">
                            <div class="card-img">
                                <img src={kutija} alt="Mobirise"/>
                            </div>
                            <div class="card-box">
                                <h4 class="card-title mbr-fonts-style display-7">
                                    Pakovanje u ekološku ambalažu
                                </h4>
                                <p class="mbr-text mbr-fonts-style display-7">
                                    Sa naše plantaže
                                </p>
                            </div>
                            <div class="mbr-section-btn text-center"><a href="index.html#form1-y" class="btn btn-primary display-4">
                                    poruči</a></div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </section>

<section class="features16 cid-qR4M4KR0lP" id="features16-a">
    <div class="container align-center">
        <h2 class="pb-3 mbr-fonts-style mbr-section-title display-2">
            MI SMO TU ZA VAS&nbsp;</h2>
        <h3 class="pb-5 mbr-section-subtitle mbr-fonts-style mbr-light display-5">
            Trešnje su izvor zdravlja u letnjim mesecima i uživajte u njima. Za to ćemo se pobrinuti mi. Najmanja količina koju možete da poručite je 4 kilograma trešanja. Trešnje su pažljivo birane i dobićete sigurno zdravu, sočnu i ukusnu hranu, pravo iz voćnjaka&nbsp;</h3>
        <div class="row media-row">
            
        <div class="team-item col-lg-3 col-md-6">
                <div class="item-image">
                    <img src={slavko} alt="radnik"/>
                </div>
                <div class="item-caption py-3">
                    <div class="item-name px-2">
                        <p class="mbr-fonts-style display-5">
                           Slavko
                        </p>
                    </div>
                    <div class="item-role px-2">
                        <p>Proizvođač</p>
                    </div>
                   
                </div>
            </div>
            <div class="team-item col-lg-3 col-md-6">
                <div class="item-image">
                    <img src={sava} alt="radnik"/>
                </div>
                <div class="item-caption py-3">
                    <div class="item-name px-2">
                        <p class="mbr-fonts-style display-5">
                           Sava
                        </p>
                    </div>
                    <div class="item-role px-2">
                        <p>Agronom</p>
                    </div>
                    
                </div>
            </div>
            <div class="team-item col-lg-3 col-md-6">
                <div class="item-image">
                    <img src={Zora} alt="radnik"/>
                </div>
                <div class="item-caption py-3">
                    <div class="item-name px-2">
                        <p class="mbr-fonts-style display-5">
                           Zora
                        </p>
                    </div>
                    <div class="item-role px-2">
                        <p>Organizator berbe</p>
                    </div>
                    
                </div>
            </div>
            <div class="team-item col-lg-3 col-md-6">
                <div class="item-image">
                    <img src={deka} alt="deka"/>
                </div>
                <div class="item-caption py-3">
                    <div class="item-name px-2">
                        <p class="mbr-fonts-style display-5">
                           Janko
                        </p>
                    </div>
                    <div class="item-role px-2">
                        <p>deka</p>
                    </div>
                    
                </div>
            </div></div>    
    </div>
</section>

        <section class="clients cid-qR4zrUA4V2" id="clients-3">
            <div class="container mb-5">
                <div class="media-container-row">
                    <div class="col-12 align-center">
                        <h2 class="mbr-section-title pb-3 mbr-fonts-style display-2">
                            Naše trešnje&nbsp;</h2>
                        <h3 class="mbr-section-subtitle mbr-light mbr-fonts-style display-5">
                        
                        </h3>
                    </div>
                </div>
            </div>

            <div class="container">
            {/* <Carousel /> */}
                 {/* <div class="carousel slide" data-ride="carousel" role="listbox" >
                    <div class="carousel-inner" data-visible="5" >
                        <div class="carousel-item active">
                                <div class="media-container-row">
                                    <div class="col-md-12">
                                        <div class="wrap-img ">
                                            <img src={pet} alt="Slide1"  class="img-responsive clients-img" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="media-container-row">
                                <div class="col-md-12">
                                    <div class="wrap-img ">
                                        <img src={pet} alt="Slide2"  class="img-responsive clients-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="media-container-row">
                                <div class="col-md-12">
                                    <div class="wrap-img ">
                                        <img src={pet} alt="Slide3"  class="img-responsive clients-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="media-container-row">
                                <div class="col-md-12">
                                    <div class="wrap-img ">
                                        <img src={pet} alt="Slide3" class="img-responsive clients-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item ">
                            <div class="media-container-row">
                                <div class="col-md-12">
                                    <div class="wrap-img ">
                                        <img src={pet} alt="Slide3" class="img-responsive clients-img"  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-controls">
                        <a data-app-prevent-settings="" class="carousel-control carousel-control-prev" role="button" data-slide="prev">
                            <span aria-hidden="true" class="mbri-left mbr-iconfont"></span>
                            <span class="sr-only">Prethodna</span>
                        </a>
                        <a data-app-prevent-settings="" class="carousel-control carousel-control-next" role="button" data-slide="next">
                            <span aria-hidden="true" class="mbri-right mbr-iconfont"></span>
                            <span class="sr-only">Sledeća</span>
                        </a>
                    </div>
                   
                </div>  */}
            </div> 
                
           
        </section>

        <section class="mbr-section form1 cid-qSk293DIm9" id="form1-y">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="title col-12 col-lg-8">
                        <h2 class="mbr-section-title align-center pb-3 mbr-fonts-style display-2">
                            POŠALJITE UPIT</h2>
                        <h3 class="mbr-section-subtitle align-center mbr-light pb-3 mbr-fonts-style display-5">Kontaktirajte nas da biste poručili ili ukoliko imate pitanja</h3>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="media-container-column col-lg-8" data-form-type="formoid">


                    <Forma />
                            
                            {/* <form class="mbr-form"  method="post" name="Mobirise Form" action="#"  data-netlify="true" data-netlify-honeypot="bot-field" ><input type="hidden" name="bot-field" form-name="Mobirise Form" data-form-email="true" value="Mobirise Form" data-form-field="Email"/>
                                <div class="row row-sm-offset">
                                    <div class="col-md-4 multi-horizontal" data-for="name">
                                        <div class="form-group">
                                            <label class="form-control-label mbr-fonts-style display-7" for="name-form1-y">Ime</label>
                                            <input type="text" class="form-control" name="name" data-form-field="Name" required="" id="name-form1-y"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 multi-horizontal" data-for="email">
                                        <div class="form-group">
                                            <label class="form-control-label mbr-fonts-style display-7" for="email-form1-y">Email</label>
                                            <input type="email" class="form-control" name="email" data-form-field="Email" required="" id="email-form1-y"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 multi-horizontal" data-for="phone">
                                        <div class="form-group">
                                            <label class="form-control-label mbr-fonts-style display-7" for="phone-form1-y">Telefon</label>
                                            <input type="tel" class="form-control" name="phone" data-form-field="Phone" id="phone-form1-y"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" data-for="message">
                                    <label class="form-control-label mbr-fonts-style display-7" for="message-form1-y">Poruka</label>
                                    <textarea type="text" class="form-control" name="message" rows="7" data-form-field="Message" id="message-form1-y"></textarea>
                                </div>
                    
                                <span class="input-group-btn"><button type="submit" class="btn btn-form btn-primary display-4">Pošalji</button></span>
                            </form> */}
                    </div>
                </div>
            </div>
        </section>

        <section once="" class="cid-qR4zxaahf3" id="footer7-4">
            <div class="container">
                <div class="media-container-row align-center mbr-white">
                    <div class="row row-links">
                        <ul class="foot-menu">               
                        <li class="foot-menu-item mbr-fonts-style display-7">
                                <a class="text-white mbr-bold" href="#" target="_blank">O nama</a>
                        </li>
                        <li class="foot-menu-item mbr-fonts-style display-7">
                                <a class="text-white mbr-bold" href="#" target="_blank">Usluge</a>
                        </li>
                        <li class="foot-menu-item mbr-fonts-style display-7">
                                <a class="text-white mbr-bold" href="#" target="_blank">Kontakt</a>
                        </li>
                        <li class="foot-menu-item mbr-fonts-style display-7">
                                <a class="text-white mbr-bold" href="#" target="_blank">Posao</a>
                        </li>
                        <li class="foot-menu-item mbr-fonts-style display-7">
                                <a class="text-white mbr-bold" href="#" target="_blank">Delatnost</a>
                        </li>
                        </ul>
                    </div>
                    <div class="row social-row">
                        <div class="social-list align-right pb-2"> 
                            <div class="soc-item">
                                    <a href="https://twitter.com" target="_blank">
                                        <span class="socicon-twitter socicon mbr-iconfont mbr-iconfont-social"></span>
                                    </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://www.facebook.com/" target="_blank">
                                    <span class="socicon-facebook socicon mbr-iconfont mbr-iconfont-social"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://www.youtube.com/" target="_blank">
                                    <span class="socicon-youtube socicon mbr-iconfont mbr-iconfont-social"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://instagram.com/" target="_blank">
                                    <span class="socicon-instagram socicon mbr-iconfont mbr-iconfont-social"></span>
                                </a>
                            </div>
                            <div class="soc-item">
                                <a href="https://plus.google.com/" target="_blank">
                                    <span class="socicon-googleplus socicon mbr-iconfont mbr-iconfont-social"></span>
                                </a>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row row-copirayt">
                        <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                            © Copyright 2024 &nbsp;- All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </section>

</div>
 
    );
  }
}

export default App;
